<template>
  <div>
    <b-button size="sm" variant="success" v-b-modal.modal-1
      >New Activities</b-button
    >
    <b-modal id="modal-1" title="Register Activity" size="xl">
      <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
      <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
        <div class="row">
          <div class="col-md-6" v-if="projects.length > 0">
            <b-form-group
              id="input-group-2"
              label="Projects:"
              label-for="input-2"
            >
              <select
                name=""
                id=""
                class="form-control"
                v-model="form._project_id"
                @change="projectChange"
                :disabled="!showAssetDetails"
              >
                <option value="">Select Project</option>
                <option
                  :value="project._id"
                  v-for="(project, index) in projects"
                  :key="index"
                >
                  {{ project.name }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="col-md-6" v-if="this.form._project_id">
            <b-form-group
              id="input-group-2"
              label="Assignment Level:"
              label-for="input-2"
            >
              <select
                name=""
                id=""
                class="form-control"
                v-model="form.assignment_level"
                @change="AssignmentLevelChange"
              >
                <option value="">Select Assignment</option>
                <option value="project_level">Project Level</option>
                <option value="zone_level">Zone Level</option>
                <option value="plot_level">Plot Level</option>
              </select>
            </b-form-group>
          </div>

          <div class="col-md-6" v-if="zones.length > 0">
            <b-form-group id="input-group-2" label="Zone:" label-for="input-2">
              <select
                name=""
                id=""
                class="form-control"
                v-model="form._zone_id"
                @change="zoneChange"
                :disabled="!showAssetDetails"
              >
                <option value="">Select Zone</option>
                <option
                  :value="zone._id"
                  v-for="(zone, index) in zones"
                  :key="index"
                >
                  {{ zone.code }}
                </option>
              </select>
            </b-form-group>
          </div>

          <div class="col-md-6" v-if="plots.length > 0">
            <b-form-group id="input-group-2" label="Plot:" label-for="input-2">
              <select
                name=""
                id=""
                class="form-control"
                v-model="form._plot_id"
                multiple
              >
                <option value="">Select Plot</option>
                <option
                  :value="plot._plot_id._id"
                  v-for="(plot, index) in plots"
                  :key="index"
                >
                  {{ plot._plot_id.code }}
                </option>
              </select>
            </b-form-group>
          </div>
        </div>

        <template v-if="!showAssetDetails">
          <div class="row">
            <div class="col-md-12">
              <h5>{{ showPlotAlignmentDetails }}</h5>
            </div>

            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                label="Select Activity type :"
                label-for="input-2"
              >
                <b-form-select
                  v-model="form._activityTypeID"
                  :options="activitiesType"
                  value-field="_id"
                  text-field="name"
                >
                  <template #first>
                    <b-form-select-option value="" disabled
                      >Select Activity Category</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="input-2"
                  v-model="form.description"
                  placeholder="Enter description..."
                  rows="5"
                  max-rows="8"
                ></b-form-textarea>
              </b-form-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-1"
                    label="Start Date:"
                    label-for="input-1"
                  >
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="form.start_date"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-1"
                    label="End Date:"
                    label-for="input-1"
                  >
                    <b-form-datepicker
                      id="example-datepicker1"
                      v-model="form.end_date"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-1"
                    label="Frequency:"
                    label-for="input-1"
                  >
                    <select
                      v-model="form.frequency"
                      required="required"
                      class="form-control"
                    >
                      <option value="">Select Frequency</option>
                      <option value="Daily">Daily</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Yearly">Yearly</option>
                      <option value="Half-yearly">Half-yearly</option>
                      <option value="Quarter-yearly">Quarter-yearly</option>
                      <option value="On need basis">On need basis</option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="No of Workers:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.no_of_worker"
                      placeholder="Enter No of Workers"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <b-form-group
                id="input-group-2"
                label="Resource:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.resources"
                  placeholder="Enter Resource"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Image:"
                label-for="input-2"
              >
                <b-form-file
                  id="file"
                  ref="file"
                  v-model="form.image"
                  :state="Boolean(form.image)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="uploadImage"
                ></b-form-file>
              </b-form-group>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="form.visible"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox value="true">Visible</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="form.notification"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox value="true"
                        >Send Notification to the Customer</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-1"
                    label="Status:"
                    label-for="input-1"
                  >
                    <select
                      v-model="form.activity_status"
                      required="required"
                      class="form-control"
                    >
                      <option value="">Select Activity status</option>
                      <option value="1">Pending</option>
                      <option value="2">In Progress</option>
                      <option value="3">Completed</option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="Progress:"
                    label-for="input-2"
                  >
                    <select
                      v-model="form.progress"
                      required="required"
                      class="form-control"
                    >
                      <option value="">Select Activity status</option>
                      <option value="0">0%</option>

                      <option value="25">25%</option>
                      <option value="50">50%</option>
                      <option value="75">75%</option>
                      <option value="100">100%</option>
                    </select>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-form>
      <template #modal-footer="{ ok, close }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="success"
          @click="submitForm()"
          :disabled="isDisable"
        >
          Register
        </b-button>
        <b-button size="sm" variant="danger" @click="close()"> Close </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        _activityTypeID: '',
        description: '',
        start_date: '',
        end_date: '',
        frequency: '',
        no_of_worker: '',
        resources: '',
        image: '',
        assignment_level: '',
        _project_id: '',
        _zone_id: '',
        _plot_id: '',
        notification: true,
        visible: true,
        progress: '',
        activity_status: '',
      },

      projects: [],
      zones: [],
      plots: [],
      isDisable: false,
      success: false,
    };
  },
  mounted() {
    this.getProjects();
  },
  props: ['activitiesType'],
  computed: {
    showAssetDetails() {
      if (this.form.assignment_level == 'project_level') {
        return this.form._project_id ? false : true;
      } else if (this.form.assignment_level == 'zone_level') {
        return this.form._zone_id ? false : true;
      } else if (this.form.assignment_level == 'plot_level') {
        return this.form._plot_id ? false : true;
      } else {
        return true;
      }
    },
    showPlotAlignmentDetails() {
      if (this.form.assignment_level == 'project_level') {
        return 'Project Level Activity';
      } else if (this.form.assignment_level == 'zone_level') {
        return 'Zone Level  Activity';
      } else if (this.form.assignment_level == 'plot_level') {
        return 'Plot Level Activity';
      } else {
        return true;
      }
    },
  },
  methods: {
    getProjects() {
      window.axios.get('project').then((resp) => {
        console.log(resp.data);
        this.projects = resp.data.data;
      });
    },
    AssignmentLevel() {
      (this.zones = []), (this.plots = []), (this.form._project_id = '');
      this.form._zone_id = '';
      this.form._plot_id = '';
      window.axios.get('project').then((resp) => {
        console.log(resp.data);
        this.projects = resp.data.data;
      });
    },
    AssignmentLevelChange() {
      this.zones = [];
      this.plots = [];
      this.form._zone_id = '';
      this.form._plot_id = '';
      let assignment_level = this.form.assignment_level;
      if (
        assignment_level == 'zone_level' ||
        assignment_level == 'plot_level'
      ) {
        this.selectedProject = this.projects.filter((number) => {
          return number._id == this.form._project_id ? number.name : false;
        });
        window.axios
          .get('/project-zones/' + this.form._project_id)
          .then((resp) => {
            this.zones = resp.data.data;
            console.log(this.zones);
          });
      }
    },
    projectChange() {
      (this.zones = []), (this.plots = []);
      this.form._zone_id = '';
      this.form._plot_id = '';
      this.form.assignment_level = '';
      let assignment_level = this.form.assignment_level;
      if (
        assignment_level == 'zone_level' ||
        assignment_level == 'plot_level'
      ) {
        this.selectedProject = this.projects.filter((number) => {
          return number._id == this.form._project_id ? number.name : false;
        });
        window.axios
          .get('/project-zones/' + this.form._project_id)
          .then((resp) => {
            this.zones = resp.data.data;
            console.log(this.zones);
          });
      }
    },
    zoneChange() {
      let assignment_level = this.form.assignment_level;
      if (
        assignment_level == 'project_level' ||
        assignment_level == 'plot_level'
      ) {
        this.selectedZone = this.zones.filter((number) => {
          return number._id == this.form._zone_id ? number.code : false;
        });
        window.axios
          .get('/all-plots-assigned/' + this.form._zone_id)
          .then((resp) => {
            this.plots = resp.data.data;
          });
      }
    },
    uploadImage() {
      this.form.image = this.$refs.file.files[0];
    },

    submitForm() {
      let resp = confirm('Are you sure you want to create event ?');

      if (resp) {
        let formData = new FormData();
        formData.append('_activityTypeID', this.form._activityTypeID);
        formData.append('description', this.form.description);
        formData.append('start_date', this.form.start_date);
        formData.append('end_date', this.form.end_date);
        formData.append('frequency', this.form.frequency);
        formData.append('no_of_worker', this.form.no_of_worker);
        formData.append('resources', this.form.resources);
        formData.append('image', this.form.image);
        formData.append('assignment_level', this.form.assignment_level);
        formData.append('_project_id', this.form._project_id);
        formData.append('_zone_id', this.form._zone_id);
        formData.append('_plot_id', this.form._plot_id);
        formData.append('notification', this.form.notification);
        formData.append('visible', this.form.visible);
        formData.append('progress', this.form.progress);
        formData.append('activity_status', this.form.activity_status);
        this.isDisable = true;
        window
          .axios({
            method: 'post',
            url: 'activity',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((resp) => {
            if (resp.data.success) {
              this.success = resp.data.message;
              this.form._activityTypeID = '';
              this.form.description = '';
              this.form.start_date = '';
              this.form.end_date = '';
              this.form.frequency = '';
              this.form.no_of_worker = '';
              this.form.resources = '';
              this.form.image = '';
              this.form.assignment_level = '';
              this.form._project_id = '';
              this.form._zone_id = '';
              this.form._plot_id = '';
              this.zones = [];
              this.plots = [];
              this.$emit('create', resp.data);
              this.isDisable = false;
              setTimeout(() => (this.success = false), 3000);
            }
          });
      }
    },
  },
};
</script>
