<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row>
        <b-col md="12">
          <b-nav tabs justified>
            <b-nav-item
              :to="'/activities/' + $route.params.code + '/all'"
              exact
              exact-active-class="active"
              @click="fetchData"
              >All</b-nav-item
            >
            <b-nav-item
              :to="'/activities/' + $route.params.code + '/project_level'"
              exact
              exact-active-class="active"
              @click="fetchData"
              >Project Level</b-nav-item
            >
            <b-nav-item
              :to="'/activities/' + $route.params.code + '/zone_level'"
              exact
              exact-active-class="active"
              @click="fetchData"
              >Zone Level
            </b-nav-item>
            <b-nav-item
              :to="'/activities/' + $route.params.code + '/plot_level'"
              exact
              exact-active-class="active"
              @click="fetchData"
              >Plot Level</b-nav-item
            >
          </b-nav>
        </b-col>
      </b-row>
      <b-row class="mb-2 mt-3">
        <b-col md="10">
          <h5 class="heading">{{ pageTitle }} Activities</h5>
          <p>List of the Activities in {{ $route.params.code }} project</p>
        </b-col>
        <b-col md="2" class="text-right">
          <activity-create
            @create="fetchData"
            :activitiesType="activitiesType"
          ></activity-create>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col md="2">
          <!-- Select User Per Page  -->
          <b-form-select v-model="item_per_page" @change="changeItemNumber">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="50">50</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col md="10">
          <!-- Filter -->
          <b-form-group label="" label-for="filter-input" class="mb-0">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="dataTable rounded-left"
        :outlined="true"
        :items="items"
        :fields="fields"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        head-variant="none"
        table-variant="none"
        :busy="isBusy"
      >
        <template #cell(#)="data">
          <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
          <span v-else>{{
            (currentPage - 1) * item_per_page + (data.index + 1)
          }}</span>
        </template>
        <template #cell(image)="row">
          <img
            :src="row.item.image"
            class="rounded"
            width="50px"
            height="50px"
          />
        </template>
        <template #cell(start_date)="row">
          {{ row.item.start_date | moment("DD-MM-YYYY") }}
        </template>
        <template #cell(end_date)="row">
          {{ row.item.start_date | moment("DD-MM-YYYY") }}
        </template>
        <template #cell(created_ts)="row">
          {{ row.item.start_date | moment("DD-MM-YYYY") }}
        </template>
        <template #cell(progress_status)="row">
          <span v-if="row.item.progress_status == 1">Pending</span>
          <span v-else-if="row.item.progress_status == 2">In Progress</span>
          <span v-else-if="row.item.progress_status == 3">Completed</span>
        </template>

        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            id="dropdown-1"
            text="Actions"
            variant="primary"
          >
            <b-dropdown-item>
              <router-link
                :to="'/activities/' + row.item._id"
                style="text-decoration: none; color: #000"
              >
                View
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link
                :to="'/activities/edit/activity/' + row.item._id"
                style="text-decoration: none; color: #000"
                >Edit
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>

      <div
        v-if="message !== ''"
        class="h5 text-danger text-center font-weight-bold"
      >
        {{ message }}
      </div>
      <div v-else class="d-flex justify-content-center mt-4">
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import activityCreate from "@/components/activities/create";
export default {
  components: {
    activityCreate,
  },
  data() {
    return {
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      sortBy: "name",
      sortDesc: false,
      filter: null,
      isBusy: false,
      items: [],
      fields: [
        "#",
        "image",
        {
          key: "description",
          label: "Description",
        },
        {
          key: "code",
          label: "Activity Code",
          sortable: true,
        },
        {
          key: "_activityTypeID.name",
          label: "Activity Type",
          sortable: true,
        },
        {
          key: "progress_status",
          label: "Activity Status",
          sortable: true,
        },
        {
          key: "progress",
          label: "Activity Progress",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Start Date",
          sortable: true,
        },
        {
          key: "end_date",
          label: "End Date",
          sortable: true,
        },
        {
          key: "created_ts",
          label: "Created Date",
          sortable: true,
        },
        "actions",
      ],
      datas: [],
      activitiesType: "",
      message: "",
    };
  },
  mounted() {
    this.fetchData();
    this.getActivityType();
  },
  computed: {
    rows() {
      return this.items.length;
    },
    pageTitle() {
      let sentence = this.$route.params.assignment_level
        .toLowerCase()
        .split("_");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ");
    },
  },
  methods: {
    getActivityType() {
      window.axios.get("activity-type").then((resp) => {
        console.log(resp);
        this.activitiesType = resp.data.data;
      });
    },
    changeItemNumber() {
      this.perPage = this.item_per_page;
    },
    fetchData() {
      this.isBusy = true;
      this.message = "";
      window.axios.get("activity").then((resp) => {
        if (resp.data.success) {
          this.isBusy = false;
          this.datas = resp.data.data;
          if (this.$route.params.assignment_level === "all") {
            this.items = resp.data.data.filter((item) => {
              return (
                item.code && item.code.slice(0, 3) == this.$route.params.code
              );
            });
          } else {
            this.items = resp.data.data.filter((item) => {
              return (
                item.code &&
                item.code.slice(0, 3) == this.$route.params.code &&
                item.assignment_level == this.$route.params.assignment_level
              );
            });
          }
          if (this.items.length == 0) {
            this.message = "Sorry! No Data Available";
          }
        }
        setTimeout(() => (this.success = false), 3000);
      });
    },
  },
};
</script>

<style scoped>
.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 3.5%;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #454545;
}

/deep/ .dataTable thead {
  background-color: #dcffd9;
  height: 64px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: "Poppins", sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #303030;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable tr td {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
.nav-link {
  color: #1ea041;
}
</style>
